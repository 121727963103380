import { render, staticRenderFns } from "./center.vue?vue&type=template&id=0284b5b9&scoped=true"
import script from "./center.vue?vue&type=script&lang=js"
export * from "./center.vue?vue&type=script&lang=js"
import style0 from "./center.vue?vue&type=style&index=0&id=0284b5b9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0284b5b9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeader: require('/codebuild/output/src4033840381/src/holaluz-conversion-conversionsolar-solarsalesagentui/src/ui/components/TheHeader/index.js').default})
